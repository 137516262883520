import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Home = ({ data }) => (
  <Layout>
    <Seo
      title="jcbaey"
      lang="en"
      meta={[
        {
          name: 'og:image',
          content: data.authorImage.childImageSharp.resize.src,
        },
      ]}
    />

    <h1 className="mb-8">Cloud architect and Entrepreneur</h1>

    <StaticImage
      title="website author"
      alt="Jean-Christophe Baey"
      src="../components/author/images/jcbaey_art.jpg"
      loading="eager"
      placeholder="blurred"
      style={{ margin: '0 auto', maxWidth: 400, display: 'block' }}
      width={400}
    />

    <p className="mt-8">
      Hi!{' '}
      <span role="img" aria-label="hand">
        🖖
      </span>{' '}
      I work as a{' '}
      <strong>
        cloud solution architect at{' '}
        <a href="https://www.linkedin.com/company/renault/">Renault SW Labs in Toulouse</a>, France
      </strong>
      . I am a passionate Software Architect, Full Stack Engineer, and Technology enthusiast.
    </p>
    <p>
      Even after spending more than a decade with writing software, I still enjoy most parts of
      developing, planning, and designing scalable applications for the web and mobile devices. I
      focus on reliable service architectures based on serverless and managed components on Azure
      and AWS.
    </p>
    <p className="mb-16">
      I am also an <strong>Entrepreneur</strong>, creator of one of the best{' '}
      <strong>Screen-capture tool</strong> available on <strong>Windows</strong>:{' '}
      <a href="https://www.screenpresso.com?utm_source=jcbaey">Screenpresso</a>, on <strong>macOS</strong>:&nbsp;
      <a href="https://www.screenpolo.com?utm_source=jcbaey">Screenpolo</a>{' '}and author of the french book&nbsp;
      <a href="https://www.amazon.fr/dp/1980883653/">
        Bitcoin, Ethereum et Blockchain Economie: Comprendre l’essentiel de la (r)évolution annoncée
        en 25 Questions-Réponses
      </a>{' '}
      explaining what are <strong>cryptocurrencies and blockchains</strong>.
    </p>
    {/* <Divider /> */}
    <section className="border-gray-500 border-t-2  pt-16 pb-16" id="articles">
      <h1 className="mt-0">Articles</h1>
      <ul className="">
        {data.allMarkdownRemark.nodes.map((post) => (
          <li key={post.id}>
            <h2 className="mb-0">
              <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
            </h2>
            <span className="block font-sans font-normal md:text-xl text-base text-gray-600 my-2">
              {post.frontmatter.subtitle}
            </span>
            <span className="text-gray-500 font-normal font-sans md:text-base text-sm block">
              {post.frontmatter.date} · {post.fields.readingTime.text}
            </span>
          </li>
        ))}
      </ul>
    </section>
  </Layout>
);

export default Home;

// import { colors } from '../lib/tailwind-utils';
// const bgColor = colors['gray-100']; // #f7fafc

export const pageQuery = graphql`
  # query($bgColor: String = "#f7fafc") {
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
          author
          subtitle
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
    # https://github.com/gatsbyjs/gatsby/blob/c175f9ecfb0ed7a4e1d53cc233e3ecdb5801ec3d/packages/gatsby-image/README.md
    authorImage: file(relativePath: { eq: "components/author/images/jcbaey_art.jpg" }) {
      publicURL
      childImageSharp {
        # fluid(maxWidth: 1240, maxHeight: 800, fit: CONTAIN, background: $bgColor) {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
        resize(width: 1200, height: 628) {
          src
        }
      }
    }
  }
`;

console.log('pageQuery', pageQuery);
